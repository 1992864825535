<template>
  <div>
    <pageBuilder :save="save" :editID="editID" :onClose="function () { onClose() }" v-if="edit === 1"/>
    <flixDashboard v-else>
      <template v-slot:content>
        <div class="flix-container">
          <div class="flix-row" :key="updateSave">
            <div class="flix-col-md-12">
              <span class="flix-html-info flix-text-info"><flixIcon id="info-sign" /> {{ $t('message.targetPageInfo') }}</span>
            </div>
            <div class="flix-col-md-6" v-for="(data, index) in save" :key="index">
              <div class="flix-well">
                <div class="flix-form-group">
                  <targetPageEditor :onChangeAssistents="function(id) { onChangeAssistents(id) }" :onDelete="function(id) { onDelete(id) }" :save="data" :onEdit="function (id) { onClose(id) }"/>
                </div>
              </div>
            </div>
            <div class="flix-col-md-12">
              <div class="flix-form-group">
                <addBtn :onClick="function () { addTargetPage() }">
                  <template v-slot:text>
                    {{ $t('message.add', {name: $tc('message.targetPage', 1)}) }}
                  </template>
                </addBtn>
              </div>
            </div>
          </div>
        </div>
      </template>
    </flixDashboard>
  </div>
</template>
<script>
import { standardLayout } from '@/components/targetPage/standardLayout.js'
export default {
  components: {
    pageBuilder () { return import('@/components/targetPage/pageBuilder') },
    targetPageEditor () { return import('@/components/targetPage/edit') }
  },
  props: {},
  mounted () {
    this.getSave()
  },
  computed: {

  },
  data () {
    return {
      edit: this.$route.params.edit,
      variables: this.$getUserVariables(),
      save: [],
      editID: false,
      updateSave: 0
    }
  },
  methods: {
    onChangeAssistents (id) {
      var active
      this.save.forEach(function (v) {
        if (v.ID === id) {
          active = v
        }
      })

      this.save.forEach(function (v, k) {
        if (v.ID !== id) {
          active.assistents.forEach(function (a) {
            if (v.assistents.indexOf(a) !== -1) {
              this.save[k].assistents.splice(v.assistents.indexOf(a), 1)
              this.$store.dispatch('setTargetPage', {
                user: this.$getUserVariables().user.md5_id,
                ID: v.ID,
                data: v.layout,
                meta: { assistents: v.assistents, method: v.method },
                callback: function () { }
              })
            }
          }.bind(this))
        }
      }.bind(this))

      this.updateSave = new Date().getTime()
      // window.console.log(active)
    },
    getSave () {
      this.$store.dispatch('getTargetPages', {
        user: this.variables.user.md5_id,
        callback: function (ret) {
          this.save = ret
        }.bind(this)
      })
    },
    addTargetPage () {
      var data = {
        ID: new Date().getTime(),
        assistents: [],
        method: 'targetPage',
        layout: standardLayout
      }

      this.$store.dispatch('setTargetPage', {
        user: this.variables.user.md5_id,
        ID: data.ID,
        data: data.layout,
        meta: { assistents: data.assistents, method: data.method },
        callback: function () { this.save.push(data) }.bind(this)
      })
    },
    onDelete (ID) {
      var key = false
      this.save.forEach(function (s, k) {
        if (s.ID === ID) {
          key = k
        }
      })
      this.$flix_post({
        url: 'pagebuilder/targetpage/delete',
        data: {
          user: this.variables.user.md5_id,
          ID: this.save[key].ID
        },
        callback: function (ret) { window.console.log(ret); this.save.splice(key, 1) }.bind(this)

      })
    },
    onClose (ID) {
      if (this.edit * 1 === 0) {
        this.editID = ID
        this.edit = 1
      } else {
        this.editID = false
        this.edit = 0
      }
    }
  }
}
</script>
<style lang="sass" scoped>
</style>
